import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Contact, Footer, Past, SEO, Splash, Upcoming } from "../components"
import "./global.scss"
import "./index.scss"

export default () => {
  let [contactDrawerIsOpen, setContactDrawerIsOpen] = useState(false)

  const toggleContactDrawer = state => {
    if (state === "open") {
      setContactDrawerIsOpen(true)
    } else {
      setContactDrawerIsOpen(false)
    }
  }

  const {
    allMarkdownRemark: { nodes },
  } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { path: { nin: ["/about"] } } }
        sort: { order: DESC, fields: frontmatter___date }
        limit: 9
      ) {
        nodes {
          id
          frontmatter {
            date(formatString: "MM.DD.YYYY")
            description
            path
            thumbnail
            time
            title
            type
          }
        }
      }
    }
  `)

  const {
    0: { frontmatter: upcoming },
  } = { ...nodes.filter(({ frontmatter: el }) => el.path === "/upcoming") }
  const programs = nodes.filter(
    ({ frontmatter: el }) => el.path !== "/upcoming"
  )

  return (
    <div id="home" style={{ opacity: 0 }}>
      <SEO title="Discrit" description="Art in Conversation" />

      <Contact
        isOpen={contactDrawerIsOpen}
        closeOnClick={toggleContactDrawer}
      />

      <Splash toggleContactDrawer={toggleContactDrawer} />

      {upcoming.title ? <Upcoming data={upcoming} /> : null}

      <Past programs={programs} />

      <Footer />
    </div>
  )
}
